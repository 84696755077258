<template>
  <div style="height: 100%">
    <a-card style="height: 100%" :loading="loading">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-row type="flex" justify="space-between">
            <a-col>
              <a-typography-text class="fw-semibold text-gray-8 fs-6">
                {{ title }}
              </a-typography-text>
            </a-col>
          </a-row>

          <a-typography-text class="text-gray-8 d-block mt-3">
            Total de facturas por periodo
          </a-typography-text>

          <a-row align="middle">
            <ShopOutlined class="mr-2" :style="{ fontSize: '36px' }" />
            <a-typography-text class="fw-semibold display-3 text-gray-9">
              {{ collection_data.total }}
            </a-typography-text>
          </a-row>

          <a-tag color="processing"> Total Facturas </a-tag>

          <a-typography-text class="text-gray-9 d-block mt-5">
            Vencidas
          </a-typography-text>
          <a-progress
            :percent="collection_data.percentageExpired"
            :status="
              collection_data.percentageExpired === 0 ? 'success' : 'normal'
            "
            strokeColor="orange"
          />

          <a-typography-text class="text-gray-9 d-block">
            Por vencer
          </a-typography-text>
          <a-progress
            :percent="collection_data.percentageToExpire"
            :status="
              collection_data.percentageToExpire === 0 ? 'success' : 'normal'
            "
            strokeColor="blue"
          />
        </a-col>
        <a-col>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              margin-left: auto;
              margin-right: 0;
            "
          >
            <a-button type="primary" size="large" @click="openDetailsTable"
              >Ver detalles</a-button
            >
          </div>

          <div style="height: 90%; display: flex">
            <img
              style="align-self: flex-end"
              width="250"
              src="../../assets/images/detail-card.svg"
            />
          </div>
        </a-col>
      </a-row>
    </a-card>

    <table-details ref="table_details_ref" />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, computed, watch, ref } from 'vue';
import moment from 'moment';
import TotalDetailsShowInfoTable from './TotalDetailsShowInfoTable.vue';
import { ShopOutlined } from '@ant-design/icons-vue';
import {
  FETCH_INVOICE_DEFAULTERS_SUMMARY,
  GET_INVOICE_DEFAULTERS_SUMMARY,
  GET_DATE_FILTER_DASHBOARD,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  components: {
    'table-details': TotalDetailsShowInfoTable,
    ShopOutlined
  },
  props: {
    title: {
      type: String,
      default: 'Agregue un titulo'
    }
  },
  setup() {
    const store = useStore();

    const collection_data = computed(() => {
      return store.getters[GET_INVOICE_DEFAULTERS_SUMMARY];
    });

    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    const selected_period = computed(
      () => store.getters[GET_DATE_FILTER_DASHBOARD]
    );

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => selected_period.value,
      () => {
        getMainData();
      }
    );

    const loading = ref(false);
    const getMainData = async () => {
      loading.value = true;
      try {
        const payload = {};
        // tomo los ids de las compañias seleccionadas
        if (selected_companies.value.length > 0) {
          payload.companies = selected_companies.value.join(',');
        }

        // Tomo el periodo seleccionado
        if (selected_period.value != undefined && selected_period.value != '') {
          payload.period = moment(selected_period.value, 'YYYY-MM').format(
            'YYYY-MM'
          );
        }

        // lo transformo en parametros para la consulta
        let params = '';
        Object.keys(payload).forEach((key, index) => {
          if (index === 0) {
            params += `?${key}=${payload[key]}`;
          } else {
            params += `&${key}=${payload[key]}`;
          }
        });
        await store.dispatch(FETCH_INVOICE_DEFAULTERS_SUMMARY, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    // * Tabla con detalles
    const table_details_ref = ref();
    const openDetailsTable = () => {
      table_details_ref.value.openModal();
    };

    onMounted(() => {
      getMainData();
    });

    return {
      collection_data,
      table_details_ref,
      loading,
      openDetailsTable
    };
  }
};
</script>
