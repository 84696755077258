<template>
  <a-modal
    v-model:visible="visible"
    title="Detalle de morosidad"
    :width="1000"
    :footer="false"
  >
    <a-typography-text>
      Aquí encontrarás el detalle de morosidad de tus clientes
    </a-typography-text>

    <div class="my-3">
      <a-radio-group v-model:value="table_selection">
        <a-radio-button value="expired">Vencidas</a-radio-button>
        <a-radio-button value="to_expired">Por vencer</a-radio-button>
      </a-radio-group>
    </div>

    <a-table :data-source="table_data" :columns="columns"> </a-table>
  </a-modal>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import notification from '../../utils/notifications';
import {
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES,
  GET_DATE_FILTER_DASHBOARD,
  FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST,
  GET_INVOICE_DEFAULTERS_SUMMARY_LIST
} from '@/store/types';

export default {
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const store = useStore();
    const table_selection = ref('expired');

    const table_data = computed(() => {
      const values = store.getters[GET_INVOICE_DEFAULTERS_SUMMARY_LIST];
      if (values != []) {
        return values.map((obj) => {
          return {
            id: obj.id,
            key: obj.id,
            numberPolicy: obj.numberPolicy,
            businessName: obj.businessName,
            period: obj.period,
            folio: obj.folio,
            issueDate: obj.issueDate,
            pendingDays: obj.pendingDays
          };
        });
      }
      return [];
    });

    /* const pagination_props = reactive({
      total: table_data?.value.length,
      pageSize: 5,
      current: 1,
    }); */

    const columns = ref([
      {
        title: 'Póliza',
        dataIndex: 'numberPolicy',
        key: 'numberPolicy'
      },
      {
        title: 'Razón social',
        dataIndex: 'businessName',
        key: 'businessName'
      },
      {
        title: 'Periodo',
        dataIndex: 'period',
        key: 'period'
      },
      {
        title: 'N° factura',
        dataIndex: 'folio',
        key: 'folio'
      },
      {
        title: 'Fecha emisión',
        dataIndex: 'issueDate',
        key: 'issueDate'
      },
      {
        title: 'Días pendiente',
        dataIndex: 'pendingDays',
        key: 'pendingDays'
      }
      // {
      //   title: 'Acción',
      //   dataIndex: 'id',
      //   key: 'id',
      // },
    ]);

    /* const changeCurrentPage = (page) => {
      pagination_props.current = page.current;
    }; */

    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    const selected_period = computed(
      () => store.getters[GET_DATE_FILTER_DASHBOARD]
    );

    watch(table_selection, () => {
      getMainData();
    });

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => selected_period.value,
      () => {
        getMainData();
      }
    );

    const getMainData = async () => {
      loading.value = true;
      try {
        const payload = {};

        // tomo los ids de las compañias seleccionadas
        if (selected_companies.value.length > 0) {
          payload.companies = selected_companies.value.join(',');
        }

        // Tomo el periodo seleccionado
        if (selected_period.value != undefined && selected_period.value != '') {
          payload.period = moment(selected_period.value, 'YYYY-MM').format(
            'YYYY-MM'
          );
        }

        // Tipo de tabla
        if (table_selection.value === 'to_expired') {
          payload.type = 'TO_EXPIRE';
        } else {
          payload.type = 'EXPIRED';
        }

        // lo transformo en parametros para la consulta
        let params = '';
        Object.keys(payload).forEach((key, index) => {
          if (index === 0) {
            params += `?${key}=${payload[key]}`;
          } else {
            params += `&${key}=${payload[key]}`;
          }
        });
        await store.dispatch(FETCH_INVOICE_DEFAULTERS_SUMMARY_LIST, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading.value = false;
    };

    const openModal = () => {
      visible.value = true;
      getMainData();
    };

    return {
      visible,
      table_selection,
      table_data,
      // pagination_props,
      columns,
      loading,
      // changeCurrentPage,
      openModal
    };
  }
};
</script>
